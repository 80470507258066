.wysiwyg-2col {
  position: relative;
  margin-top: 120px;
  margin-bottom: 120px;

  @media (max-width: 767.98px) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  &.invert {
    .content-area {
      flex-direction: row;
    }
  }

  .content-area {
    display: flex;
    flex-wrap: wrap;
    column-gap: 25px;
    row-gap: 24px;
    flex-direction: row-reverse;

    @media (max-width: 767.98px) {
      flex-direction: column;
    }

    .text-area,
    .image-area {
      flex: 0 0 calc((100% - 25px) / 2);
      max-width: calc((100% - 25px) / 2);

      @media (max-width: 767.98px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    h2 {
      margin-bottom: 24px;
    }
    h3 {
      color: #2a5caa;
      font-family: "Avenir Next";
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 112.5%;
      margin-top: 48px;
      margin-bottom: 12px;

      @media (max-width: 767.98px) {
        font-size: 22px;
      }
    }
    h4 {
      margin-top: 48px;
      margin-bottom: 12px;
    }
    h5 {
      margin-top: 48px;
      margin-bottom: 12px;
    }
    h6 {
      font-weight: 500;
      line-height: 122.222%;
      text-transform: uppercase;
      margin-top: 48px;
      margin-bottom: 12px;
    }
    p {
      a {
        color: #2a5caa;
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
    ul,
    ol {
      margin-top: 24px;
      padding-left: 24px;

      li {
        margin-bottom: 12px;
        a {
          color: #2a5caa;
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
    }
    .img-area {
      margin-top: 48px;
      margin-bottom: 48px;
      img {
        width: 100%;
        margin-bottom: 12px;
      }
      p {
        color: #282828;
        font-family: "Avenir Next";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 114.286%;

        a {
          color: #2a5caa;
          text-decoration-line: underline;
          text-underline-offset: 2px;
        }
      }
    }
    blockquote {
      margin-bottom: 24px;
    }
  }
}
