@font-face {
  font-family: "Avenir Next";
  src: url("../../fonts/AvenirNext-DemiBold.eot");
  src: local("Avenir Next Demi Bold"), local("AvenirNext-DemiBold"), url("../../fonts/AvenirNext-DemiBold.eot?#iefix") format("embedded-opentype"), url("../../fonts/AvenirNext-DemiBold.woff2") format("woff2"), url("../../fonts/AvenirNext-DemiBold.woff") format("woff"), url("../../fonts/AvenirNext-DemiBold.ttf") format("truetype"), url("../../fonts/AvenirNext-DemiBold.svg#AvenirNext-DemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Avenir Next";
  src: url("../../fonts/AvenirNext-Bold.eot");
  src: local("Avenir Next Bold"), local("AvenirNext-Bold"), url("../../fonts/AvenirNext-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/AvenirNext-Bold.woff2") format("woff2"), url("../../fonts/AvenirNext-Bold.woff") format("woff"), url("../../fonts/AvenirNext-Bold.ttf") format("truetype"), url("../../fonts/AvenirNext-Bold.svg#AvenirNext-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Avenir Next";
  src: url("../../fonts/AvenirNext-Regular.eot");
  src: local("Avenir Next Regular"), local("AvenirNext-Regular"), url("../../fonts/AvenirNext-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/AvenirNext-Regular.woff2") format("woff2"), url("../../fonts/AvenirNext-Regular.woff") format("woff"), url("../../fonts/AvenirNext-Regular.ttf") format("truetype"), url("../../fonts/AvenirNext-Regular.svg#AvenirNext-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Avenir Next";
  src: url("../../fonts/AvenirNext-Medium.eot");
  src: local("Avenir Next Medium"), local("AvenirNext-Medium"), url("../../fonts/AvenirNext-Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/AvenirNext-Medium.woff2") format("woff2"), url("../../fonts/AvenirNext-Medium.woff") format("woff"), url("../../fonts/AvenirNext-Medium.ttf") format("truetype"), url("../../fonts/AvenirNext-Medium.svg#AvenirNext-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

/***************
  Typography 
 ***************/
h1,
h2,
h3,
h4,
h5,
h6,
body,
a,
span,
p,
ul,
li,
* {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; }

html {
  overflow-y: scroll !important;
  scroll-behavior: auto !important;
  height: 100lvh; }

body {
  position: relative;
  font-family: "Avenir Next";
  color: #282828;
  font-weight: normal;
  padding-top: 276px;
  left: 0;
  right: 0;
  /* The container must be positioned relative: */
  /* style the items (options), including the selected item: */
  /* Style items (options): */
  /* Hide the items when the select box is closed: */ }
  body.no-overflow {
    overflow: unset; }
  body.scroll-lock, body.lock-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%; }
  body.lockscroll {
    width: 100vw;
    height: 100vh;
    position: fixed; }
  body .invisible {
    opacity: 0; }
  body.modal-open {
    padding-right: 0 !important;
    margin-bottom: 0 !important;
    transform: none; }
  body .modal-backdrop.fade {
    transition: all 0.35s; }
  body .modal-backdrop.show {
    opacity: 1;
    background: #000; }
  body .fancybox-slide--html {
    padding: 0; }
  body .fancybox-bg {
    opacity: 1 !important;
    background: #121b32; }
  body .fancybox-navigation,
  body .fancybox-infobar {
    display: none; }
  body .fancybox-content {
    border-radius: 0px 40px 0px 0px;
    background-color: #fff;
    padding: 48px;
    width: 720px;
    max-width: 100%; }
    @media (max-width: 767.98px) {
      body .fancybox-content {
        padding: 24px;
        border-radius: 0;
        min-height: 100vh; } }
  body .fancybox-close-small {
    padding: 0;
    width: 60px;
    height: 21px;
    background-image: url(../../images/menu-times.png);
    background-size: 11px;
    background-position: center left;
    background-repeat: no-repeat;
    top: 24px !important;
    right: 48px !important;
    padding: 0 !important;
    padding-left: 16px !important; }
    @media (max-width: 767.98px) {
      body .fancybox-close-small {
        right: 24px !important; } }
    body .fancybox-close-small::before {
      content: "Close";
      color: #e02827;
      font-family: "Avenir Next";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.28px;
      position: relative;
      top: -1px; }
    body .fancybox-close-small svg {
      display: none; }
  body section {
    z-index: 2;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }
  body .content-area {
    position: relative;
    width: 1248px;
    max-width: 100%;
    padding: 0 24px;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 1728px) {
      body .content-area {
        width: 1488px; } }
  body a,
  body button,
  body input,
  body select,
  body textarea {
    transition: all 0.35s; }
    body a:focus, body a:hover,
    body button:focus,
    body button:hover,
    body input:focus,
    body input:hover,
    body select:focus,
    body select:hover,
    body textarea:focus,
    body textarea:hover {
      box-shadow: none !important;
      outline: none !important;
      text-decoration: none;
      transition: all 0.35s; }
  body sup {
    top: -1.7em;
    font-size: 30%; }
  body a {
    color: inherit;
    text-decoration: none; }
    body a:hover {
      color: inherit; }
  body p {
    color: #282828;
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; }
  body h1 {
    color: #fff;
    font-family: "Avenir Next";
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 106.667%; }
    @media (max-width: 991.98px) {
      body h1 {
        font-size: 54px; } }
    @media (max-width: 767.98px) {
      body h1 {
        font-size: 48px; } }
  body h2 {
    color: #2a5caa;
    font-family: "Avenir Next";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; }
    @media (max-width: 767.98px) {
      body h2 {
        font-size: 32px; } }
  body h3 {
    color: #2a5caa;
    font-family: "Avenir Next";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; }
    @media (max-width: 767.98px) {
      body h3 {
        font-size: 22px; } }
  body h4 {
    color: #2a5caa;
    font-family: "Avenir Next";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 116.667%; }
    @media (max-width: 767.98px) {
      body h4 {
        font-size: 20px; } }
  body h5 {
    color: #282828;
    font-family: "Avenir Next";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; }
    @media (max-width: 767.98px) {
      body h5 {
        font-size: 18px; } }
  body h6 {
    color: #2a5caa;
    font-family: "Avenir Next";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; }
    @media (max-width: 767.98px) {
      body h6 {
        font-size: 16px; } }
  body strong {
    font-weight: 700; }
  body .medium {
    font-weight: 500; }
  body .no-margin-top {
    margin-top: 0 !important; }
  body .green {
    color: #299083; }
    body .green-bg {
      background-color: #299083; }
  body .blue {
    color: #2a5caa; }
    body .blue-bg {
      background-color: #2a5caa; }
  body .orange {
    color: #f26651; }
    body .orange-bg {
      background-color: #f26651; }
  body .purple {
    color: #52247f; }
    body .purple-bg {
      background-color: #52247f; }
  body .btn {
    border-radius: 0;
    border: 1px solid #52247f;
    padding: 11px;
    color: #fff;
    text-align: center;
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    background-color: #52247f;
    transition: all 0.35s; }
    body .btn:hover {
      color: #fff; }
    @media (min-width: 992px) {
      body .btn.btn-primary:hover {
        background: #fff;
        border-color: #52247f;
        color: #52247f;
        transition: all 0.35s; } }
    @media (max-width: 991.98px) {
      body .btn.btn-primary:hover, body .btn.btn-primary:focus {
        background-color: #52247f;
        border-color: #52247f; } }
    body .btn.btn-secondary {
      border: 1px solid #fff;
      background: rgba(40, 40, 40, 0.25); }
      @media (min-width: 992px) {
        body .btn.btn-secondary:hover {
          background: #fff;
          border-color: #fff;
          color: #52247f;
          transition: all 0.35s; } }
      @media (max-width: 991.98px) {
        body .btn.btn-secondary:hover, body .btn.btn-secondary:focus {
          background-color: rgba(40, 40, 40, 0.25);
          border-color: rgba(40, 40, 40, 0.25);
          color: #fff; } }
    body .btn.btn-yellow-purple {
      background-color: #ffda3a;
      border-color: #ffda3a;
      color: #52247f; }
      @media (min-width: 992px) {
        body .btn.btn-yellow-purple:hover {
          background: #52247f;
          border-color: #ffda3a;
          color: #ffda3a;
          transition: all 0.35s; } }
      @media (max-width: 991.98px) {
        body .btn.btn-yellow-purple:hover, body .btn.btn-yellow-purple:focus {
          background-color: #ffda3a;
          border-color: #ffda3a;
          color: #52247f; } }
    body .btn.btn-blue-white {
      background-color: #2a5caa;
      border-color: #2a5caa;
      color: #fff; }
      @media (min-width: 992px) {
        body .btn.btn-blue-white:hover {
          background: #fff;
          border-color: #2a5caa;
          color: #2a5caa;
          transition: all 0.35s; } }
      @media (max-width: 991.98px) {
        body .btn.btn-blue-white:hover, body .btn.btn-blue-white:focus {
          background-color: #2a5caa;
          border-color: #2a5caa;
          color: #fff; } }
    body .btn.btn-white-trans {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      @media (min-width: 992px) {
        body .btn.btn-white-trans:hover {
          background: #fff;
          border-color: #fff;
          color: #2a5caa;
          transition: all 0.35s; } }
      @media (max-width: 991.98px) {
        body .btn.btn-white-trans:hover, body .btn.btn-white-trans:focus {
          background-color: transparent;
          border-color: #fff;
          color: #fff; } }
    body .btn.btn-white-opac {
      background-color: rgba(40, 40, 40, 0.25);
      border-color: #fff;
      color: #fff; }
      @media (min-width: 992px) {
        body .btn.btn-white-opac:hover {
          background: #fff;
          border-color: #fff;
          color: #2a5caa;
          transition: all 0.35s; } }
      @media (max-width: 991.98px) {
        body .btn.btn-white-opac:hover, body .btn.btn-white-opac:focus {
          background-color: rgba(40, 40, 40, 0.25);
          border-color: #fff;
          color: #fff; } }
  body blockquote {
    position: relative;
    padding-left: 24px;
    margin-bottom: 36px; }
    body blockquote::before {
      content: "";
      width: 8px;
      height: 100%;
      background: #93ca68;
      position: absolute;
      top: 0;
      left: 0; }
    body blockquote p {
      color: #000;
      font-family: "Avenir Next";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 122.222%;
      margin-bottom: 0; }
  body .custom-select {
    max-width: 100%;
    position: relative;
    text-align: left;
    padding: 0; }
    body .custom-select select {
      display: none;
      /*hide original SELECT element: */ }
  body .select-selected {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    z-index: 2;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    background: linear-gradient(90deg, rgba(42, 92, 170, 0.06) 0%, rgba(42, 92, 170, 0) 100%);
    border: none;
    color: #282828;
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    padding: 9px 12px;
    width: 100%;
    height: 38px;
    transition: all 0.35s; }
    body .select-selected::after {
      content: "";
      width: 10px;
      height: 10px;
      background-image: url(../../images/svg/arrow-bottom.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%); }
    body .select-selected.select-arrow-active::after {
      transform: translateY(-50%) scale(-1); }
  body .select-items div {
    text-align: left;
    border: none;
    cursor: pointer;
    padding: 9.5px 0;
    margin-left: 12px;
    margin-right: 12px;
    color: #000;
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.28px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
    @media (min-width: 992px) {
      body .select-items div:hover {
        color: #000; } }
    body .select-items div:last-child {
      border-bottom: none; }
  body .select-items {
    position: absolute;
    background: #fff;
    box-shadow: none;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 99;
    border-radius: 0;
    font-weight: 400;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 5;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
  body .select-hide {
    display: none; }

header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  transition: all 0.35s;
  max-height: 100vh;
  max-height: 100lvh;
  background-color: #fff;
  transform: translateY(-162px);
  transition: all 0.7s; }
  @media (max-width: 767.98px) {
    header {
      transition: all 0s; } }
  header.active {
    min-height: 100vh;
    min-height: calc(100lvh + 162px); }
    header.active.scrolled {
      transform: translateY(-162px); }
  header.scrolled {
    transform: translateY(-276px);
    transition: all 0.7s; }
  header.start {
    transform: translateY(0); }
    @media (max-width: 991.98px) {
      header.start .content-area .navbar .container-fluid .navbar-collapse {
        height: calc(100lvh - 350px); } }
  header .content-area .navbar {
    padding-top: 12px;
    padding-bottom: 12px; }
    header .content-area .navbar .container-fluid {
      align-items: flex-end; }
      @media (max-width: 991.98px) {
        header .content-area .navbar .container-fluid {
          align-items: center; } }
      header .content-area .navbar .container-fluid .navbar-brand {
        padding: 0;
        z-index: 3; }
        header .content-area .navbar .container-fluid .navbar-brand img {
          height: 90px;
          object-fit: contain; }
      header .content-area .navbar .container-fluid .navbar-toggler {
        padding: 0; }
        header .content-area .navbar .container-fluid .navbar-toggler .navbar-toggler-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 17.68px;
          height: 17.68px;
          padding: 0;
          background-image: url(../../images/menu-times.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          filter: brightness(0) saturate(100%) invert(31%) sepia(85%) saturate(588%) hue-rotate(181deg) brightness(90%) contrast(97%); }
          @media (max-width: 767.98px) {
            header .content-area .navbar .container-fluid .navbar-toggler .navbar-toggler-icon {
              background: url(../../images/menu-times.png);
              padding: 0;
              width: 24px;
              height: 24px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              filter: brightness(0) saturate(100%) invert(31%) sepia(85%) saturate(588%) hue-rotate(181deg) brightness(90%) contrast(97%); } }
        header .content-area .navbar .container-fluid .navbar-toggler.collapsed .navbar-toggler-icon {
          background-image: url(../../images/menu-bar.png);
          width: 24px;
          height: 24px;
          filter: brightness(0) saturate(100%) invert(31%) sepia(85%) saturate(588%) hue-rotate(181deg) brightness(90%) contrast(97%); }
      header .content-area .navbar .container-fluid .menu-top {
        position: absolute;
        right: 0;
        top: 12px;
        width: 100%;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        @media (max-width: 1199.98px) {
          header .content-area .navbar .container-fluid .menu-top {
            display: none; } }
        header .content-area .navbar .container-fluid .menu-top a:not(.btn) {
          color: #2a5caa;
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 13px;
          margin-left: 24px; }
          header .content-area .navbar .container-fluid .menu-top a:not(.btn)::after {
            content: "";
            width: 12px;
            height: 12px;
            background-image: url(../../images/svg/arrow-right-blue.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            display: inline-block;
            position: relative;
            margin-left: 3px;
            top: 1px;
            transition: all 0.35s; }
          @media (min-width: 992px) {
            header .content-area .navbar .container-fluid .menu-top a:not(.btn):hover::after {
              transform: translateX(3px);
              transition: all 0.35s; } }
        header .content-area .navbar .container-fluid .menu-top .search-top {
          margin-left: 24px;
          position: relative; }
          header .content-area .navbar .container-fluid .menu-top .search-top img {
            cursor: pointer; }
          header .content-area .navbar .container-fluid .menu-top .search-top input {
            position: relative;
            opacity: 0;
            width: 0;
            transition: all 0.35s; }
          header .content-area .navbar .container-fluid .menu-top .search-top.active input {
            width: 150px;
            opacity: 1;
            pointer-events: all;
            transition: all 0.35s; }
        header .content-area .navbar .container-fluid .menu-top .btn {
          margin-left: 16px; }
      header .content-area .navbar .container-fluid .navbar-collapse {
        transition: all 0s; }
        @media (max-width: 991.98px) {
          header .content-area .navbar .container-fluid .navbar-collapse {
            width: 100%;
            height: calc(100lvh - 200px);
            overflow-y: auto;
            overflow-x: hidden; } }
        header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav {
          margin-left: auto; }
          header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item {
            margin-left: 28px; }
            @media (max-width: 1199.98px) {
              header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item {
                margin-left: 15px; } }
            @media (max-width: 991.98px) {
              header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item {
                margin-left: 0; } }
            header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .nav-link::before {
              content: "";
              width: calc(100% + 56px);
              height: 100%;
              position: absolute;
              top: 0;
              left: -28px;
              pointer-events: none; }
            header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .nav-link::after {
              content: "";
              width: 8px;
              height: 5px;
              background-image: url(../../images/svg/arrow-bottom.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              margin-left: 4px;
              display: inline-block;
              position: relative;
              top: 2px; }
            header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu {
              position: absolute;
              width: 100vw;
              left: 50%;
              top: 100%;
              transform: translateX(-50%);
              background: linear-gradient(0deg, rgba(42, 92, 170, 0.04) 0%, rgba(42, 92, 170, 0.04) 100%), #fff;
              box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
              padding: 48px 0px;
              opacity: 0;
              pointer-events: none;
              transition: all 0.35s; }
              @media (max-width: 991.98px) {
                header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu {
                  transition: all 0s; } }
              header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu.inactive {
                opacity: 0 !important;
                pointer-events: none; }
              @media (max-width: 991.98px) {
                header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu.active {
                  position: relative;
                  opacity: 1 !important;
                  box-shadow: none;
                  background: transparent;
                  padding: 0;
                  transition: all 0s;
                  width: 100%;
                  pointer-events: all; }
                  header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu.active .megamenu-content {
                    padding-left: 0;
                    padding-right: 0; }
                    header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu.active .megamenu-content .column-megamenu {
                      flex: 0 0 calc((100% - 36px) / 2);
                      max-width: calc((100% - 36px) / 2); } }
              header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content {
                width: 1248px;
                max-width: 100%;
                padding-left: 24px;
                padding-right: 24px;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                flex-wrap: wrap;
                column-gap: 24px; }
                @media (min-width: 1728px) {
                  header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content {
                    width: 1488px; } }
                header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content .title-megamenu {
                  flex: 0 0 100%;
                  max-width: 100%; }
                  header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content .title-megamenu .with-arrow {
                    color: #2a5caa;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 26px;
                    display: inline-block; }
                    header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content .title-megamenu .with-arrow::after {
                      content: "";
                      width: 16px;
                      height: 16px;
                      background-image: url(../../images/svg/arrow-right-blue.svg);
                      background-size: contain;
                      background-repeat: no-repeat;
                      background-position: center;
                      display: inline-block;
                      position: relative;
                      margin-left: 3px;
                      top: 1px;
                      transition: all 0.35s; }
                    @media (min-width: 992px) {
                      header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content .title-megamenu .with-arrow:hover::after {
                        transform: translateX(3px);
                        transition: all 0.35s; } }
                header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content .column-megamenu {
                  flex: 0 0 calc((100% - 72px) / 4);
                  max-width: calc((100% - 72px) / 4); }
                  header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content .column-megamenu .with-arrow {
                    color: #2a5caa;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 22px;
                    display: inline-block;
                    margin-top: 36px;
                    margin-bottom: 16px; }
                    @media (max-width: 1199.98px) {
                      header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content .column-megamenu .with-arrow {
                        font-size: 16px; } }
                    @media (max-width: 991.98px) {
                      header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content .column-megamenu .with-arrow {
                        margin-top: 16px; } }
                    header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content .column-megamenu .with-arrow::after {
                      content: "";
                      width: 14px;
                      height: 14px;
                      background-image: url(../../images/svg/arrow-right-blue.svg);
                      background-size: contain;
                      background-repeat: no-repeat;
                      background-position: center;
                      display: inline-block;
                      position: relative;
                      margin-left: 3px;
                      top: 1px;
                      transition: all 0.35s; }
                    @media (min-width: 992px) {
                      header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content .column-megamenu .with-arrow:hover::after {
                        transform: translateX(3px);
                        transition: all 0.35s; } }
                  header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content .column-megamenu a:not(.with-arrow) {
                    position: relative;
                    padding: 4px;
                    color: #282828;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    display: block;
                    margin-bottom: 4px; }
                    header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content .column-megamenu a:not(.with-arrow):hover {
                      background: #fff;
                      color: #2a5caa; }
                  header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content .column-megamenu .sub-level {
                    padding-left: 24px !important;
                    position: relative !important; }
                    header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.has-megamenu .megamenu .megamenu-content .column-megamenu .sub-level::before {
                      content: "";
                      width: 4px;
                      height: 4px;
                      background: #299083;
                      position: absolute;
                      display: block;
                      left: 12px;
                      top: 50%;
                      transform: translateY(-50%); }
            header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link {
              position: relative;
              color: #282828;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              padding: 4px 0 24px;
              transform: translateY(12px); }
              @media (max-width: 1199.98px) {
                header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link {
                  font-size: 14px; } }
              @media (max-width: 991.98px) {
                header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.active {
                  color: #2a5caa; }
                  header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.active::after {
                    transform: scale(-1);
                    filter: brightness(0) saturate(100%) invert(29%) sepia(75%) saturate(688%) hue-rotate(182deg) brightness(98%) contrast(96%); } }
              header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link span {
                position: relative;
                top: 3px; }
                header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link span::before {
                  content: "";
                  width: 100%;
                  height: 1px;
                  background-color: rgba(255, 255, 255, 0.2);
                  position: absolute;
                  bottom: -8px;
                  left: 0;
                  opacity: 0;
                  transition: all 0.35s; }
              header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.toggle-menu img {
                width: 24px; }
              header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.active span::before {
                opacity: 1;
                transition: all 0.35s; }
            @media (min-width: 992px) {
              header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item:hover .nav-link {
                color: #2a5caa; }
                header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item:hover .nav-link::before {
                  pointer-events: all; }
                header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item:hover .nav-link::after {
                  transform: scale(-1);
                  filter: brightness(0) saturate(100%) invert(29%) sepia(75%) saturate(688%) hue-rotate(182deg) brightness(98%) contrast(96%); }
              header .content-area .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item:hover .megamenu {
                opacity: 1;
                pointer-events: all;
                transition: all 0.35s; } }
        header .content-area .navbar .container-fluid .navbar-collapse .menu-mobile {
          padding-top: 80px;
          padding-bottom: 24px; }
          header .content-area .navbar .container-fluid .navbar-collapse .menu-mobile .left-menu {
            margin-bottom: 64px; }
            header .content-area .navbar .container-fluid .navbar-collapse .menu-mobile .left-menu .link-box a {
              color: #fff;
              font-family: "NeoGram Trial";
              font-size: 26px;
              font-style: normal;
              font-weight: 700;
              line-height: 90%;
              margin-bottom: 24px;
              display: block;
              max-width: max-content; }
          header .content-area .navbar .container-fluid .navbar-collapse .menu-mobile .right-menu .menu-favicon {
            margin-bottom: 16px; }
            header .content-area .navbar .container-fluid .navbar-collapse .menu-mobile .right-menu .menu-favicon img {
              height: 32px; }
          header .content-area .navbar .container-fluid .navbar-collapse .menu-mobile .right-menu .menu-right-link {
            margin-bottom: 24px; }
            header .content-area .navbar .container-fluid .navbar-collapse .menu-mobile .right-menu .menu-right-link a {
              display: block;
              color: #fff;
              font-family: "NeoGram Trial";
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%;
              letter-spacing: 1.4px;
              text-transform: uppercase;
              margin-bottom: 8px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.2);
              width: max-content; }
          header .content-area .navbar .container-fluid .navbar-collapse .menu-mobile .right-menu .menu-social a {
            margin-right: 32px; }
            header .content-area .navbar .container-fluid .navbar-collapse .menu-mobile .right-menu .menu-social a img {
              height: 24px; }

footer {
  position: relative;
  background: radial-gradient(133.82% 149.85% at 5.8% 17.8%, #2a5caa 0%, #00b3e4 100%);
  padding-top: 36px;
  padding-bottom: 36px; }
  footer .content-area .top-column {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    column-gap: 36px; }
    footer .content-area .top-column .footer-logo {
      flex: 0 0 150px;
      max-width: 150px;
      margin-bottom: 48px; }
      footer .content-area .top-column .footer-logo a img {
        filter: brightness(0) invert(1); }
    footer .content-area .top-column .footer-link {
      flex: 1 1 auto;
      text-align: right;
      margin-bottom: 48px; }
      footer .content-area .top-column .footer-link a:not(.btn) {
        color: #fff;
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 13px;
        margin-left: 36px; }
        @media (max-width: 767.98px) {
          footer .content-area .top-column .footer-link a:not(.btn) {
            margin-left: 16px;
            margin-bottom: 16px;
            display: inline-block; } }
        footer .content-area .top-column .footer-link a:not(.btn)::after {
          content: "";
          width: 12px;
          height: 12px;
          background-image: url(../../images/svg/arrow-right-blue.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          display: inline-block;
          position: relative;
          margin-left: 3px;
          top: 1px;
          filter: brightness(0) invert(1);
          transition: all 0.35s; }
        @media (min-width: 992px) {
          footer .content-area .top-column .footer-link a:not(.btn):hover::after {
            transform: translateX(3px);
            transition: all 0.35s; } }
      footer .content-area .top-column .footer-link .btn {
        margin-left: 36px; }
        @media (max-width: 767.98px) {
          footer .content-area .top-column .footer-link .btn {
            margin-left: 16px; } }
    footer .content-area .top-column .footer-subscribe {
      flex: 0 0 384px;
      max-width: 384px;
      margin-bottom: 48px; }
      @media (max-width: 991.98px) {
        footer .content-area .top-column .footer-subscribe {
          margin-left: auto; } }
      @media (max-width: 767.98px) {
        footer .content-area .top-column .footer-subscribe {
          max-width: 100%; } }
      footer .content-area .top-column .footer-subscribe h3 {
        color: #fff;
        text-align: center;
        font-family: "Avenir Next";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 114.286%;
        letter-spacing: 0.56px;
        text-transform: uppercase;
        margin-bottom: 24px; }
      footer .content-area .top-column .footer-subscribe form {
        display: flex; }
        footer .content-area .top-column .footer-subscribe form input {
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.2) 100%);
          height: 38px;
          width: 100%;
          border: none;
          padding: 5px 12px;
          color: #282828; }
          footer .content-area .top-column .footer-subscribe form input::placeholder {
            color: #282828; }
        footer .content-area .top-column .footer-subscribe form button {
          color: #fff;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 14px;
          background: #2a5caa;
          border: 1px solid #2a5caa;
          padding: 11px; }
  footer .content-area .footer-cat {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px; }
    footer .content-area .footer-cat .cat-column {
      flex: 0 0 calc((100% - 120px) / 6);
      max-width: calc((100% - 120px) / 6);
      padding-bottom: 24px; }
      @media (max-width: 767.98px) {
        footer .content-area .footer-cat .cat-column {
          flex: 0 0 calc((100% - 24px) / 2);
          max-width: calc((100% - 24px) / 2); } }
      footer .content-area .footer-cat .cat-column h3 {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 12px; }
      footer .content-area .footer-cat .cat-column a {
        color: #ddeaff;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 12px;
        display: block; }
  footer .content-area .footer-copyright {
    border-top: 1px solid rgba(235, 235, 235, 0.5);
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between; }
    @media (max-width: 767.98px) {
      footer .content-area .footer-copyright {
        flex-direction: column-reverse;
        align-items: flex-start; } }
    @media (max-width: 767.98px) {
      footer .content-area .footer-copyright .copyright-text {
        flex: 0 0 100%;
        max-width: 100%; } }
    footer .content-area .footer-copyright .copyright-text p {
      color: #ddeaff;
      font-family: "Avenir Next";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      /* 128.571% */
      margin-bottom: 0; }
      footer .content-area .footer-copyright .copyright-text p a {
        font-weight: 600;
        display: inline-block; }
    footer .content-area .footer-copyright .footer-social {
      position: relative;
      top: 6px; }
      @media (max-width: 767.98px) {
        footer .content-area .footer-copyright .footer-social {
          margin-bottom: 20px; } }
      footer .content-area .footer-copyright .footer-social a {
        font-size: 24px;
        color: #fff;
        margin-left: 24px; }
        @media (max-width: 767.98px) {
          footer .content-area .footer-copyright .footer-social a {
            margin-left: 0;
            margin-right: 24px; } }

.wysiwyg-note {
  position: relative;
  margin-top: 120px;
  margin-bottom: 120px; }
  @media (max-width: 767.98px) {
    .wysiwyg-note {
      margin-top: 60px;
      margin-bottom: 60px; } }
  .wysiwyg-note .content-area .text-box {
    background: #f5f5f5;
    width: 522px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 36px; }
    .wysiwyg-note .content-area .text-box p {
      color: #282828;
      font-family: "Avenir Next";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%;
      margin-bottom: 16px; }
    .wysiwyg-note .content-area .text-box ul {
      padding-left: 16px; }
      .wysiwyg-note .content-area .text-box ul li {
        color: #282828;
        font-family: "Avenir Next";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
        margin-bottom: 16px; }

.wysiwyg-1col {
  position: relative;
  margin-top: 120px;
  margin-bottom: 120px; }
  @media (max-width: 767.98px) {
    .wysiwyg-1col {
      margin-top: 60px;
      margin-bottom: 60px; } }
  .wysiwyg-1col.text-center .content-area blockquote {
    padding-left: 0;
    margin-bottom: 24px;
    padding-bottom: 24px; }
    .wysiwyg-1col.text-center .content-area blockquote::before {
      width: 384px;
      max-width: 100%;
      height: 2px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      top: unset; }
  .wysiwyg-1col.text-center .content-area ol,
  .wysiwyg-1col.text-center .content-area ul {
    text-align: left;
    width: 588px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px; }
  .wysiwyg-1col .content-area {
    width: 840px; }
    @media (min-width: 1728px) {
      .wysiwyg-1col .content-area {
        width: 1048px; } }
    .wysiwyg-1col .content-area h2 {
      margin-bottom: 24px; }
    .wysiwyg-1col .content-area h3 {
      color: #2a5caa;
      font-family: "Avenir Next";
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 112.5%;
      margin-top: 48px;
      margin-bottom: 12px; }
      @media (max-width: 767.98px) {
        .wysiwyg-1col .content-area h3 {
          font-size: 22px; } }
    .wysiwyg-1col .content-area h4 {
      margin-top: 48px;
      margin-bottom: 12px; }
    .wysiwyg-1col .content-area h5 {
      margin-top: 48px;
      margin-bottom: 12px; }
    .wysiwyg-1col .content-area h6 {
      font-weight: 500;
      line-height: 122.222%;
      text-transform: uppercase;
      margin-top: 48px;
      margin-bottom: 12px; }
    .wysiwyg-1col .content-area p a {
      color: #2a5caa;
      text-decoration: underline;
      text-underline-offset: 2px; }
    .wysiwyg-1col .content-area ul,
    .wysiwyg-1col .content-area ol {
      margin-top: 24px;
      padding-left: 24px; }
      .wysiwyg-1col .content-area ul li,
      .wysiwyg-1col .content-area ol li {
        margin-bottom: 12px; }
        .wysiwyg-1col .content-area ul li a,
        .wysiwyg-1col .content-area ol li a {
          color: #2a5caa;
          text-decoration: underline;
          text-underline-offset: 2px; }
    .wysiwyg-1col .content-area .img-area {
      margin-top: 48px;
      margin-bottom: 48px; }
      .wysiwyg-1col .content-area .img-area img {
        width: 100%;
        margin-bottom: 12px; }
      .wysiwyg-1col .content-area .img-area p {
        color: #282828;
        font-family: "Avenir Next";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 114.286%; }
        .wysiwyg-1col .content-area .img-area p a {
          color: #2a5caa;
          text-decoration-line: underline;
          text-underline-offset: 2px; }
    .wysiwyg-1col .content-area blockquote {
      margin-bottom: 24px; }

.wysiwyg-2col {
  position: relative;
  margin-top: 120px;
  margin-bottom: 120px; }
  @media (max-width: 767.98px) {
    .wysiwyg-2col {
      margin-top: 60px;
      margin-bottom: 60px; } }
  .wysiwyg-2col.invert .content-area {
    flex-direction: row; }
  .wysiwyg-2col .content-area {
    display: flex;
    flex-wrap: wrap;
    column-gap: 25px;
    row-gap: 24px;
    flex-direction: row-reverse; }
    @media (max-width: 767.98px) {
      .wysiwyg-2col .content-area {
        flex-direction: column; } }
    .wysiwyg-2col .content-area .text-area,
    .wysiwyg-2col .content-area .image-area {
      flex: 0 0 calc((100% - 25px) / 2);
      max-width: calc((100% - 25px) / 2); }
      @media (max-width: 767.98px) {
        .wysiwyg-2col .content-area .text-area,
        .wysiwyg-2col .content-area .image-area {
          flex: 0 0 100%;
          max-width: 100%; } }
    .wysiwyg-2col .content-area h2 {
      margin-bottom: 24px; }
    .wysiwyg-2col .content-area h3 {
      color: #2a5caa;
      font-family: "Avenir Next";
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 112.5%;
      margin-top: 48px;
      margin-bottom: 12px; }
      @media (max-width: 767.98px) {
        .wysiwyg-2col .content-area h3 {
          font-size: 22px; } }
    .wysiwyg-2col .content-area h4 {
      margin-top: 48px;
      margin-bottom: 12px; }
    .wysiwyg-2col .content-area h5 {
      margin-top: 48px;
      margin-bottom: 12px; }
    .wysiwyg-2col .content-area h6 {
      font-weight: 500;
      line-height: 122.222%;
      text-transform: uppercase;
      margin-top: 48px;
      margin-bottom: 12px; }
    .wysiwyg-2col .content-area p a {
      color: #2a5caa;
      text-decoration: underline;
      text-underline-offset: 2px; }
    .wysiwyg-2col .content-area ul,
    .wysiwyg-2col .content-area ol {
      margin-top: 24px;
      padding-left: 24px; }
      .wysiwyg-2col .content-area ul li,
      .wysiwyg-2col .content-area ol li {
        margin-bottom: 12px; }
        .wysiwyg-2col .content-area ul li a,
        .wysiwyg-2col .content-area ol li a {
          color: #2a5caa;
          text-decoration: underline;
          text-underline-offset: 2px; }
    .wysiwyg-2col .content-area .img-area {
      margin-top: 48px;
      margin-bottom: 48px; }
      .wysiwyg-2col .content-area .img-area img {
        width: 100%;
        margin-bottom: 12px; }
      .wysiwyg-2col .content-area .img-area p {
        color: #282828;
        font-family: "Avenir Next";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 114.286%; }
        .wysiwyg-2col .content-area .img-area p a {
          color: #2a5caa;
          text-decoration-line: underline;
          text-underline-offset: 2px; }
    .wysiwyg-2col .content-area blockquote {
      margin-bottom: 24px; }
