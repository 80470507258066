header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  transition: all 0.35s;
  max-height: 100vh;
  // max-height: calc(100vh - env(safe-area-inset-bottom));
  max-height: 100lvh;
  background-color: #fff;
  transform: translateY(-162px);
  transition: all 0.7s;

  @media (max-width: 767.98px) {
    transition: all 0s;
  }

  &.active {
    min-height: 100vh;
    // min-height: calc(100vh - env(safe-area-inset-bottom));
    min-height: calc(100lvh + 162px);

    &.scrolled {
      transform: translateY(-162px);
    }
  }

  &.scrolled {
    transform: translateY(-276px);
    transition: all 0.7s;
  }

  &.start {
    transform: translateY(0);

    @media (max-width: 991.98px) {
      .content-area .navbar .container-fluid .navbar-collapse {
        height: calc(100lvh - 350px);
      }
    }
  }

  .content-area {
    .navbar {
      padding-top: 12px;
      padding-bottom: 12px;

      .container-fluid {
        align-items: flex-end;

        @media (max-width: 991.98px) {
          align-items: center;
        }

        .navbar-brand {
          padding: 0;
          z-index: 3;
          img {
            height: 90px;
            object-fit: contain;
          }
        }
        .navbar-toggler {
          padding: 0;
          .navbar-toggler-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 17.68px;
            height: 17.68px;
            padding: 0;
            background-image: url(../../images/menu-times.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            filter: brightness(0) saturate(100%) invert(31%) sepia(85%)
              saturate(588%) hue-rotate(181deg) brightness(90%) contrast(97%);

            @media (max-width: 767.98px) {
              background: url(../../images/menu-times.png);
              padding: 0;
              width: 24px;
              height: 24px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              filter: brightness(0) saturate(100%) invert(31%) sepia(85%)
                saturate(588%) hue-rotate(181deg) brightness(90%) contrast(97%);
            }
          }

          &.collapsed {
            .navbar-toggler-icon {
              background-image: url(../../images/menu-bar.png);
              width: 24px;
              height: 24px;
              filter: brightness(0) saturate(100%) invert(31%) sepia(85%)
                saturate(588%) hue-rotate(181deg) brightness(90%) contrast(97%);
            }
          }
        }
        .menu-top {
          position: absolute;
          right: 0;
          top: 12px;
          width: 100%;
          text-align: right;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          @media (max-width: 1199.98px) {
            display: none;
          }

          a:not(.btn) {
            color: #2a5caa;
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 13px;
            margin-left: 24px;

            &::after {
              content: "";
              width: 12px;
              height: 12px;
              background-image: url(../../images/svg/arrow-right-blue.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              display: inline-block;
              position: relative;
              margin-left: 3px;
              top: 1px;
              transition: all 0.35s;
            }

            @media (min-width: 992px) {
              &:hover {
                &::after {
                  transform: translateX(3px);
                  transition: all 0.35s;
                }
              }
            }
          }
          .search-top {
            margin-left: 24px;
            position: relative;

            img {
              cursor: pointer;
            }
            input {
              position: relative;
              opacity: 0;
              width: 0;
              transition: all 0.35s;
            }

            &.active {
              input {
                width: 150px;
                opacity: 1;
                pointer-events: all;
                transition: all 0.35s;
              }
            }
          }
          .btn {
            margin-left: 16px;
          }
        }
        .navbar-collapse {
          transition: all 0s;
          @media (max-width: 991.98px) {
            width: 100%;
            height: calc(100lvh - 200px);
            overflow-y: auto;
            overflow-x: hidden;
          }
          .navbar-nav {
            margin-left: auto;

            .nav-item {
              margin-left: 28px;

              @media (max-width: 1199.98px) {
                margin-left: 15px;
              }
              @media (max-width: 991.98px) {
                margin-left: 0;
              }

              &.has-megamenu {
                .nav-link {
                  &::before {
                    content: "";
                    width: calc(100% + 56px);
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: -28px;
                    pointer-events: none;
                  }
                  &::after {
                    content: "";
                    width: 8px;
                    height: 5px;
                    background-image: url(../../images/svg/arrow-bottom.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-left: 4px;
                    display: inline-block;
                    position: relative;
                    top: 2px;
                  }
                }
                .megamenu {
                  position: absolute;
                  width: 100vw;
                  left: 50%;
                  top: 100%;
                  transform: translateX(-50%);
                  background: linear-gradient(
                      0deg,
                      rgba(42, 92, 170, 0.04) 0%,
                      rgba(42, 92, 170, 0.04) 100%
                    ),
                    #fff;
                  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
                  padding: 48px 0px;
                  opacity: 0;
                  pointer-events: none;
                  transition: all 0.35s;

                  @media (max-width: 991.98px) {
                    transition: all 0s;
                  }

                  &.inactive {
                    opacity: 0 !important;
                    pointer-events: none;
                  }

                  @media (max-width: 991.98px) {
                    &.active {
                      position: relative;
                      opacity: 1 !important;
                      box-shadow: none;
                      background: transparent;
                      padding: 0;
                      transition: all 0s;
                      width: 100%;
                      pointer-events: all;

                      .megamenu-content {
                        padding-left: 0;
                        padding-right: 0;

                        .column-megamenu {
                          flex: 0 0 calc((100% - 36px) / 2);
                          max-width: calc((100% - 36px) / 2);
                        }
                      }
                    }
                  }

                  .megamenu-content {
                    width: 1248px;
                    max-width: 100%;
                    padding-left: 24px;
                    padding-right: 24px;
                    margin-left: auto;
                    margin-right: auto;
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 24px;

                    @media (min-width: 1728px) {
                      width: 1488px;
                    }

                    .title-megamenu {
                      flex: 0 0 100%;
                      max-width: 100%;

                      .with-arrow {
                        color: #2a5caa;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 26px;
                        display: inline-block;

                        &::after {
                          content: "";
                          width: 16px;
                          height: 16px;
                          background-image: url(../../images/svg/arrow-right-blue.svg);
                          background-size: contain;
                          background-repeat: no-repeat;
                          background-position: center;
                          display: inline-block;
                          position: relative;
                          margin-left: 3px;
                          top: 1px;
                          transition: all 0.35s;
                        }
                        @media (min-width: 992px) {
                          &:hover {
                            &::after {
                              transform: translateX(3px);
                              transition: all 0.35s;
                            }
                          }
                        }
                      }
                    }
                    .column-megamenu {
                      flex: 0 0 calc((100% - 72px) / 4);
                      max-width: calc((100% - 72px) / 4);

                      .with-arrow {
                        color: #2a5caa;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 22px;
                        display: inline-block;
                        margin-top: 36px;
                        margin-bottom: 16px;

                        @media (max-width: 1199.98px) {
                          font-size: 16px;
                        }
                        @media (max-width: 991.98px) {
                          margin-top: 16px;
                        }

                        &::after {
                          content: "";
                          width: 14px;
                          height: 14px;
                          background-image: url(../../images/svg/arrow-right-blue.svg);
                          background-size: contain;
                          background-repeat: no-repeat;
                          background-position: center;
                          display: inline-block;
                          position: relative;
                          margin-left: 3px;
                          top: 1px;
                          transition: all 0.35s;
                        }

                        @media (min-width: 992px) {
                          &:hover {
                            &::after {
                              transform: translateX(3px);
                              transition: all 0.35s;
                            }
                          }
                        }
                      }
                      a:not(.with-arrow) {
                        position: relative;
                        padding: 4px;
                        color: #282828;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px;
                        display: block;
                        margin-bottom: 4px;

                        &:hover {
                          background: #fff;
                          color: #2a5caa;
                        }
                      }
                      .sub-level {
                        padding-left: 24px !important;
                        position: relative !important;

                        &::before {
                          content: "";
                          width: 4px;
                          height: 4px;
                          background: #299083;
                          position: absolute;
                          display: block;
                          left: 12px;
                          top: 50%;
                          transform: translateY(-50%);
                        }
                      }
                    }
                  }
                }
              }

              .nav-link {
                position: relative;
                color: #282828;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                padding: 4px 0 24px;
                transform: translateY(12px);

                @media (max-width: 1199.98px) {
                  font-size: 14px;
                }

                @media (max-width: 991.98px) {
                  &.active {
                    color: #2a5caa;
                    &::after {
                      transform: scale(-1);
                      filter: brightness(0) saturate(100%) invert(29%)
                        sepia(75%) saturate(688%) hue-rotate(182deg)
                        brightness(98%) contrast(96%);
                    }
                  }
                }

                span {
                  position: relative;
                  top: 3px;

                  &::before {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background-color: rgba(255, 255, 255, 0.2);
                    position: absolute;
                    bottom: -8px;
                    left: 0;
                    opacity: 0;
                    transition: all 0.35s;
                  }
                }

                &.toggle-menu {
                  img {
                    width: 24px;
                  }
                }

                &.active {
                  span {
                    &::before {
                      opacity: 1;
                      transition: all 0.35s;
                    }
                  }
                }
              }

              @media (min-width: 992px) {
                &:hover {
                  .nav-link {
                    color: #2a5caa;
                    &::before {
                      pointer-events: all;
                    }
                    &::after {
                      transform: scale(-1);
                      filter: brightness(0) saturate(100%) invert(29%)
                        sepia(75%) saturate(688%) hue-rotate(182deg)
                        brightness(98%) contrast(96%);
                    }
                  }
                  .megamenu {
                    opacity: 1;
                    pointer-events: all;
                    transition: all 0.35s;
                  }
                }
              }
            }
          }
          .menu-mobile {
            padding-top: 80px;
            padding-bottom: 24px;

            .left-menu {
              margin-bottom: 64px;
              .link-box {
                a {
                  color: #fff;
                  font-family: "NeoGram Trial";
                  font-size: 26px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 90%;
                  margin-bottom: 24px;
                  display: block;
                  max-width: max-content;
                }
              }
            }
            .right-menu {
              .menu-favicon {
                margin-bottom: 16px;
                img {
                  height: 32px;
                }
              }
              .menu-right-link {
                margin-bottom: 24px;
                a {
                  display: block;
                  color: #fff;
                  font-family: "NeoGram Trial";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 150%;
                  letter-spacing: 1.4px;
                  text-transform: uppercase;
                  margin-bottom: 8px;
                  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                  width: max-content;
                }
              }
              .menu-social {
                a {
                  margin-right: 32px;

                  img {
                    height: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
