footer {
  position: relative;
  background: radial-gradient(
    133.82% 149.85% at 5.8% 17.8%,
    #2a5caa 0%,
    #00b3e4 100%
  );
  padding-top: 36px;
  padding-bottom: 36px;

  .content-area {
    .top-column {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      column-gap: 36px;

      .footer-logo {
        flex: 0 0 150px;
        max-width: 150px;
        margin-bottom: 48px;

        a {
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
      .footer-link {
        flex: 1 1 auto;
        text-align: right;
        margin-bottom: 48px;

        a:not(.btn) {
          color: #fff;
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 13px;
          margin-left: 36px;

          @media (max-width: 767.98px) {
            margin-left: 16px;
            margin-bottom: 16px;
            display: inline-block;
          }

          &::after {
            content: "";
            width: 12px;
            height: 12px;
            background-image: url(../../images/svg/arrow-right-blue.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            display: inline-block;
            position: relative;
            margin-left: 3px;
            top: 1px;
            filter: brightness(0) invert(1);
            transition: all 0.35s;
          }

          @media (min-width: 992px) {
            &:hover {
              &::after {
                transform: translateX(3px);
                transition: all 0.35s;
              }
            }
          }
        }
        .btn {
          margin-left: 36px;

          @media (max-width: 767.98px) {
            margin-left: 16px;
          }
        }
      }
      .footer-subscribe {
        flex: 0 0 384px;
        max-width: 384px;
        margin-bottom: 48px;

        @media (max-width: 991.98px) {
          margin-left: auto;
        }

        @media (max-width: 767.98px) {
          max-width: 100%;
        }

        h3 {
          color: #fff;
          text-align: center;
          font-family: "Avenir Next";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 114.286%;
          letter-spacing: 0.56px;
          text-transform: uppercase;
          margin-bottom: 24px;
        }
        form {
          display: flex;

          input {
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0.8) 0%,
              rgba(255, 255, 255, 0.2) 100%
            );
            height: 38px;
            width: 100%;
            border: none;
            padding: 5px 12px;
            color: #282828;

            &::placeholder {
              color: #282828;
            }
          }
          button {
            color: #fff;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px;
            background: #2a5caa;
            border: 1px solid #2a5caa;
            padding: 11px;
          }
        }
      }
    }
    .footer-cat {
      display: flex;
      flex-wrap: wrap;
      column-gap: 24px;

      .cat-column {
        flex: 0 0 calc((100% - 120px) / 6);
        max-width: calc((100% - 120px) / 6);
        padding-bottom: 24px;

        @media (max-width: 767.98px) {
          flex: 0 0 calc((100% - 24px) / 2);
          max-width: calc((100% - 24px) / 2);
        }

        h3 {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          margin-bottom: 12px;
        }
        a {
          color: #ddeaff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          margin-bottom: 12px;
          display: block;
        }
      }
    }
    .footer-copyright {
      border-top: 1px solid rgba(235, 235, 235, 0.5);
      padding-top: 30px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;

      @media (max-width: 767.98px) {
        flex-direction: column-reverse;
        align-items: flex-start;
      }

      .copyright-text {
        @media (max-width: 767.98px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
        p {
          color: #ddeaff;
          font-family: "Avenir Next";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 128.571% */
          margin-bottom: 0;

          a {
            font-weight: 600;
            display: inline-block;
          }
        }
      }
      .footer-social {
        position: relative;
        top: 6px;

        @media (max-width: 767.98px) {
          margin-bottom: 20px;
        }
        a {
          font-size: 24px;
          color: #fff;
          margin-left: 24px;

          @media (max-width: 767.98px) {
            margin-left: 0;
            margin-right: 24px;
          }
        }
      }
    }
  }
}
