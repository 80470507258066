/***************
  Typography 
 ***************/

h1,
h2,
h3,
h4,
h5,
h6,
body,
a,
span,
p,
ul,
li,
* {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

html {
  // overflow-x: hidden;
  overflow-y: scroll !important;
  scroll-behavior: auto !important;
  height: 100lvh;
}

// /* ------------------------- Hide Scrollbar -------------------------------------------------- */

// body::-webkit-scrollbar {
//   display: none;
// } /* Chrome, Safari, Opera */
// body {
//   -ms-overflow-style: none;
// } /* IE & Edge */
// html {
//   scrollbar-width: none;
// } /* Firefox */

body {
  position: relative;
  font-family: "Avenir Next";
  color: #282828;
  font-weight: normal;
  // overflow: hidden;
  padding-top: 276px;
  left: 0;
  right: 0;

  &.no-overflow {
    overflow: unset;
  }

  &.scroll-lock,
  &.lock-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }

  &.lockscroll {
    width: 100vw;
    height: 100vh;
    position: fixed;
  }

  .invisible {
    opacity: 0;
  }

  &.modal-open {
    padding-right: 0 !important;
    margin-bottom: 0 !important;
    transform: none;
  }

  .modal-backdrop.fade {
    transition: all 0.35s;
  }

  .modal-backdrop.show {
    opacity: 1;
    background: #000;
  }

  .fancybox-slide--html {
    padding: 0;
  }
  .fancybox-bg {
    opacity: 1 !important;
    background: #121b32;
  }
  .fancybox-navigation,
  .fancybox-infobar {
    display: none;
  }
  .fancybox-content {
    border-radius: 0px 40px 0px 0px;
    background-color: #fff;
    padding: 48px;
    width: 720px;
    max-width: 100%;

    @media (max-width: 767.98px) {
      padding: 24px;
      border-radius: 0;
      min-height: 100vh;
    }
  }
  .fancybox-close-small {
    padding: 0;
    width: 60px;
    height: 21px;
    background-image: url(../../images/menu-times.png);
    background-size: 11px;
    background-position: center left;
    background-repeat: no-repeat;
    top: 24px !important;
    right: 48px !important;
    padding: 0 !important;
    padding-left: 16px !important;

    @media (max-width: 767.98px) {
      right: 24px !important;
    }

    &::before {
      content: "Close";
      color: #e02827;
      font-family: "Avenir Next";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.28px;
      position: relative;
      top: -1px;
    }

    svg {
      display: none;
    }
  }

  section {
    z-index: 2;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .content-area {
    position: relative;
    width: 1248px;
    max-width: 100%;
    padding: 0 24px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 1728px) {
      width: 1488px;
    }
  }

  a,
  button,
  input,
  select,
  textarea {
    transition: all 0.35s;

    &:focus,
    &:hover {
      box-shadow: none !important;
      outline: none !important;
      text-decoration: none;
      transition: all 0.35s;
    }
  }

  sup {
    top: -1.7em;
    font-size: 30%;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }

  p {
    color: #282828;
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }

  h1 {
    color: #fff;
    font-family: "Avenir Next";
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 106.667%;
    @media (max-width: 991.98px) {
      font-size: 54px;
    }
    @media (max-width: 767.98px) {
      font-size: 48px;
    }
  }

  h2 {
    color: #2a5caa;
    font-family: "Avenir Next";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;

    @media (max-width: 767.98px) {
      font-size: 32px;
    }
  }

  h3 {
    color: #2a5caa;
    font-family: "Avenir Next";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;

    @media (max-width: 767.98px) {
      font-size: 22px;
    }
  }

  h4 {
    color: #2a5caa;
    font-family: "Avenir Next";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 116.667%;

    @media (max-width: 767.98px) {
      font-size: 20px;
    }
  }

  h5 {
    color: #282828;
    font-family: "Avenir Next";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

    @media (max-width: 767.98px) {
      font-size: 18px;
    }
  }

  h6 {
    color: #2a5caa;
    font-family: "Avenir Next";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;

    @media (max-width: 767.98px) {
      font-size: 16px;
    }
  }

  strong {
    font-weight: 700;
  }

  .medium {
    font-weight: 500;
  }

  .no-margin-top {
    margin-top: 0 !important;
  }

  .green {
    color: #299083;

    &-bg {
      background-color: #299083;
    }
  }
  .blue {
    color: #2a5caa;

    &-bg {
      background-color: #2a5caa;
    }
  }
  .orange {
    color: #f26651;

    &-bg {
      background-color: #f26651;
    }
  }
  .purple {
    color: #52247f;

    &-bg {
      background-color: #52247f;
    }
  }

  .btn {
    border-radius: 0;
    border: 1px solid #52247f;
    padding: 11px;
    color: #fff;
    text-align: center;
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    background-color: #52247f;
    transition: all 0.35s;

    &:hover {
      color: #fff;
    }

    &.btn-primary {
      @media (min-width: 992px) {
        &:hover {
          background: #fff;
          border-color: #52247f;
          color: #52247f;
          transition: all 0.35s;
        }
      }
      @media (max-width: 991.98px) {
        &:hover,
        &:focus {
          background-color: #52247f;
          border-color: #52247f;
        }
      }
    }
    &.btn-secondary {
      border: 1px solid #fff;
      background: rgba(40, 40, 40, 0.25);

      @media (min-width: 992px) {
        &:hover {
          background: #fff;
          border-color: #fff;
          color: #52247f;
          transition: all 0.35s;
        }
      }
      @media (max-width: 991.98px) {
        &:hover,
        &:focus {
          background-color: rgba(40, 40, 40, 0.25);
          border-color: rgba(40, 40, 40, 0.25);
          color: #fff;
        }
      }
    }
    &.btn-yellow-purple {
      background-color: #ffda3a;
      border-color: #ffda3a;
      color: #52247f;
      @media (min-width: 992px) {
        &:hover {
          background: #52247f;
          border-color: #ffda3a;
          color: #ffda3a;
          transition: all 0.35s;
        }
      }
      @media (max-width: 991.98px) {
        &:hover,
        &:focus {
          background-color: #ffda3a;
          border-color: #ffda3a;
          color: #52247f;
        }
      }
    }
    &.btn-blue-white {
      background-color: #2a5caa;
      border-color: #2a5caa;
      color: #fff;
      @media (min-width: 992px) {
        &:hover {
          background: #fff;
          border-color: #2a5caa;
          color: #2a5caa;
          transition: all 0.35s;
        }
      }
      @media (max-width: 991.98px) {
        &:hover,
        &:focus {
          background-color: #2a5caa;
          border-color: #2a5caa;
          color: #fff;
        }
      }
    }
    &.btn-white-trans {
      background-color: transparent;
      border-color: #fff;
      color: #fff;
      @media (min-width: 992px) {
        &:hover {
          background: #fff;
          border-color: #fff;
          color: #2a5caa;
          transition: all 0.35s;
        }
      }
      @media (max-width: 991.98px) {
        &:hover,
        &:focus {
          background-color: transparent;
          border-color: #fff;
          color: #fff;
        }
      }
    }
    &.btn-white-opac {
      background-color: rgba(40, 40, 40, 0.25);
      border-color: #fff;
      color: #fff;
      @media (min-width: 992px) {
        &:hover {
          background: #fff;
          border-color: #fff;
          color: #2a5caa;
          transition: all 0.35s;
        }
      }
      @media (max-width: 991.98px) {
        &:hover,
        &:focus {
          background-color: rgba(40, 40, 40, 0.25);
          border-color: #fff;
          color: #fff;
        }
      }
    }
  }

  blockquote {
    position: relative;
    padding-left: 24px;
    margin-bottom: 36px;

    &::before {
      content: "";
      width: 8px;
      height: 100%;
      background: #93ca68;
      position: absolute;
      top: 0;
      left: 0;
    }
    p {
      color: #000;
      font-family: "Avenir Next";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 122.222%;
      margin-bottom: 0;
    }
  }

  /* The container must be positioned relative: */
  .custom-select {
    max-width: 100%;
    position: relative;
    text-align: left;
    // max-width: max-content;
    padding: 0;

    select {
      display: none; /*hide original SELECT element: */
    }
  }

  .select-selected {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    z-index: 2;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    background: linear-gradient(
      90deg,
      rgba(42, 92, 170, 0.06) 0%,
      rgba(42, 92, 170, 0) 100%
    );
    border: none;
    color: #282828;
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    padding: 9px 12px;
    width: 100%;
    height: 38px;
    transition: all 0.35s;

    &::after {
      content: "";
      width: 10px;
      height: 10px;
      background-image: url(../../images/svg/arrow-bottom.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.select-arrow-active {
      &::after {
        transform: translateY(-50%) scale(-1);
      }
    }
  }
  /* style the items (options), including the selected item: */
  .select-items div {
    text-align: left;
    border: none;
    cursor: pointer;
    padding: 9.5px 0;
    margin-left: 12px;
    margin-right: 12px;
    color: #000;
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.28px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    @media (min-width: 992px) {
      &:hover {
        color: #000;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  /* Style items (options): */
  .select-items {
    position: absolute;
    background: #fff;
    box-shadow: none;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 99;
    border-radius: 0;
    font-weight: 400;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 5;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  /* Hide the items when the select box is closed: */
  .select-hide {
    display: none;
  }

  // .select-items div:hover,
  // .same-as-selected {
  //   background-color: rgba(0, 0, 0, 0.9);
  // }
}
