.wysiwyg-note {
  position: relative;
  margin-top: 120px;
  margin-bottom: 120px;

  @media (max-width: 767.98px) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .content-area {
    .text-box {
      background: #f5f5f5;
      width: 522px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 36px;

      p {
        color: #282828;
        font-family: "Avenir Next";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
        margin-bottom: 16px;
      }
      ul {
        padding-left: 16px;
        li {
          color: #282828;
          font-family: "Avenir Next";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 125%;
          margin-bottom: 16px;
        }
      }
    }
  }
}
